import classNames from 'classnames'
import React from 'react'
import { ButtonProps } from 'components/Button'
import Input from 'components/Input'
import type { Component } from 'constants/types'
import AuthButton from 'features/auth/components/AuthButton'
import styles from './ScienceSignUpForm.module.scss'

type Props = {
  buttonProps?: ButtonProps | {}
} & Component

export default function ScienceSignUpForm({ buttonProps = {}, className }: Props) {
  const [email, setEmail] = React.useState('')

  const commonProps = {
    size: 'xlarge',
  }

  return (
    <div className={classNames('ScienceSignUpForm', styles.this, className)}>
      <Input
        {...commonProps}
        placeholder="Enter your email address"
        value={email}
        className={styles.input}
        onChange={(event) => setEmail(event.target.value)}
      />
      <AuthButton
        {...buttonProps}
        {...commonProps}
        authProps={{
          type: 'sign-up',
          signUpEmail: email,
        }}
        color="yellow"
        className={styles.button}
      />
    </div>
  )
}
